import React, { useState, useContext, useEffect, useRef } from 'react';

import { Container } from 'components/container/Container';
import { PrismicImage } from 'types/prismicImage';
import { RichText } from 'components/contentful/rich-text/RichText';
import { VideoPlayer } from 'components/contentful/video-player/VideoPlayer'
import { UIContext } from 'context/ui';
import Chevron from 'assets/svg/chevron.svg';

import s from './VideosGallery.scss';

interface IProps {
  title?: string;
  items: Array<IItemsProps> | null;
}

interface IItemsProps {
  title: string;
  video: any;
  img?: PrismicImage;
  caption?: string;
}

export const Item = ({item}: any) => {
  const vid = useRef(null);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [clicked, setClicked] = useState(false);
  const showPlayButton = true;

  const onButtonClick = () => {
      setClicked(true);
      videoPlayer.toggleControls(true);
      videoPlayer.play();
  }

  useEffect(() => {
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
          if (vid.current) {
              const Plyr = require('plyr');
              const player = new Plyr(vid.current, 
                  {
                      controls: ['play', 'progress', 'volume'],
                      volume: 1,
                      hideControls: false,
                      vimeo: {
                          volume: 1,
                          background: false,
                          muted: false
                      }
                  });
              
              player.on('ready', () => {
                  player.toggleControls(false)
              });
              setVideoPlayer(player)
          }
      }
      return () => {setVideoPlayer(null)}
  }, [])

  useEffect(() => {
      if (videoPlayer && item.videoUrl) {
          if (item.videoUrl.includes('vimeo')){
              videoPlayer.source = {
                  type: 'video',
                  sources: [
                      {
                          src: item.videoUrl,
                          provider: 'vimeo',
                          size: 720
                      }
                  ]
              }
              videoPlayer.on('play', () => {
                  videoPlayer.muted = false
              })
          }
      }
  }, [videoPlayer])

  return (
      <div className={s('item')}>
          {
                  !clicked && showPlayButton &&
                  <div className={s.overlay}>
                      <button onClick={onButtonClick} className={s.playButton}>
                          <img src={playIcon}/> PLAY NOW
                      </button>
                  </div>
                  

              }
          {
              <video ref={vid} className={s('player')} id="player" playsinline
              style={{
                  '--plyr-control-icon-size': '20px',
                  '--plyr-video-control-color': '#1368ea',
                  '--plyr-video-control-color-hover': 'transparent',
                  '--plyr-video-controls-background': 'transparent',
                  '--plyr-color-main': '#fff',
                  '--plyr-control-spacing': '20px',
                  '--plyr-video-background': 'transparent',
              }}>
                  {
                      item.video &&
                      <source src={item.video.file.url} type="video/mp4"/>
                  }
                  
              </video>
          }
      </div>
  )
}

export const VideosGallery = ({
  videosGallery,
  style,
  marginLeft
}:
any) => {

  const title = videosGallery.title;
  const subtitle = videosGallery.subtitle;
  const items = videosGallery.items

  const { isMobile, isDesktop } = useContext(UIContext);
  const [activeItem, setActiveItem] = useState(1)
  const [playVideo, setPlayVideo] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  const nav = items?.map((item: IItemsProps, index: number) => {
    return (
      <li
        key={index}
        className={s('videosGallery__button', (index+1==activeItem) ? 'active' : '' )}
      >
        <button
          type="button"
          onClick={ () => {
            setImageLoaded(false)
            setPlayVideo(false)
            setActiveItem(index+1)
          } }
        >
          {item.title}
        </button>
      </li>
    );
  });

  const selectOptions = items?.map((item: any, index: number) => {
    return (
      <option key={index} value={index}>
        {item.title}
      </option>
    );
  });

  const videos = items?.map((item: any, index: number) => {

    const active = index+1 == activeItem

    return (
      <div key={index} className={s('videosGallery__videosWrapper__videoItem', {active})} >
        <div className={s.videosGallery__videosWrapper__videoItem__inner}>
          <div>
            <VideoPlayer videoItem={item} pause={true} regularVideo={item.video != null}/>
          </div>
        </div>
      </div>
    )
  });

  const captions = items?.map((item: any, index: number) => {

    const active = index+1 == activeItem

    return (
      <div key={index} className={s('videosGallery__caption', {active})}>
        {item.text && <RichText richText={item.text}/>}
      </div>
    )
  });

  return (
    <div className={s.videosGallery} style={style}>
      <Container>
        <div className={s.videosGallery__row}>
          <div className={s.videosGallery__col} style={{marginLeft: marginLeft}}>
            <h2 className={s.videosGallery__title}>{title}</h2>
            {
              subtitle && <h4 className={s.videosGallery__subtitle}>{subtitle}</h4>
            }
            <div className={s.videosGallery__rowInner}>
              <nav className={s.videosGallery__colNav}>
                {isDesktop && <ul>{nav}</ul>}
                {isMobile &&
                  <div className={s.videosGallery__selectContainer}>
                    <select onChange={e => {
                      setImageLoaded(false)
                      setPlayVideo(false)
                      setActiveItem(parseInt(e.currentTarget.value)+1)
                    }}>
                      {selectOptions}
                    </select>
                    <Chevron />
                  </div>
                }
              </nav>
              <div className={s.videosGallery__colVideos}>
                <div className={s.videosGallery__videosWrapper}>
                  {videos}
                </div>
                <div className={s.videosGallery__captionsWrapper}>
                  {captions}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};