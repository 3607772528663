import React, { useEffect, useRef } from "react";
import s from "./BgLine3.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const BgLine3 = ({ pageThemeLight = false }) => {
  const lineContainerRef = useRef(null);
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.from(lineRef.current, {
      drawSVG: "0%",
      duration: 1,
      ease: "none",
      scrollTrigger: {
        scroller: scroll.container,
        trigger: lineRef.current,
        scrub: 1,
        start: "top center",
        end: "bottom center"
      }
    });
  }, []);

  return (
    <div className={s.section__draw} ref={lineContainerRef}>
      <svg
        className={s("path__draw__1", { pageThemeLight })}
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          ref={lineRef}
          xmlns="http://www.w3.org/2000/svg"
          opacity="0.2"
          d="M1.5 0V844.5C6.66667 900.833 49.7 1013.5 180.5 1013.5C311.3 1013.5 602.333 1013.5 731.5 1013.5"
          stroke="#1B52AD"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};
