import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import SplitText from '../../../utils/SplitText/SplitText';

import { PrismicImage } from 'types/prismicImage';

import { Container } from 'components/container/Container';

import { scroll } from 'constants/Scroll'
import { RichText } from 'components/contentful/rich-text/RichText';
import s from './Quote.scss';

gsap.registerPlugin(SplitText);

interface IProps {
  text: string;
  author_image?: PrismicImage;
  author_name?: string;
  author_position?: string;
  smaller_text?: boolean;
  italic?: boolean;
}

export const QuoteContentful = ({
  quote,
  author_position,
  smaller_text,
  italic,
  paddingTop,
}: any) => {

  const quoteRef = useRef<HTMLElement>(null);
  const avatarRef = useRef<HTMLElement>(null);
  let tl:GSAPTimeline;

  const author_name = quote.author
  const text = quote.text
  const author_image = quote.avatar
  useEffect(():any  => {
    if (quoteRef.current) {
      setTimeout(() => {
        tl = gsap.timeline({
          scrollTrigger: {
            scroller: scroll.container,
            trigger: quoteRef.current,
            start: "top 100%"
          }
        });

        let quoteLines = new SplitText(quoteRef.current, { type: 'lines'}).lines;
        quoteLines = new SplitText(quoteLines, { type: 'lines' }).lines;

        tl
          .addLabel('start')
          .set(quoteRef.current, { autoAlpha: 1 })
          .set(quoteLines, { yPercent: 100 })
          .to(quoteLines, { duration: 1.25, stagger: 0.1, ease: 'Power3.easeInOut', yPercent: 0 })
          .to(avatarRef.current, { duration: 0.5, autoAlpha: 1 }, 'start+=1');
      }, 1500);
    }
    return () => tl && tl.kill()
  }, [])

  return (
    <div className={s.quote} style={{paddingTop}}>
      <Container>
        <div className={`${s.quote__row} | js-t-block`}>
          <div className={s.quote__col}>

            <figure>

              <h2 className={`u-visually-hidden`}>
                <RichText richText={text} quoted/>
              </h2>

              <blockquote ref={quoteRef} className={`${ s('quote__text', { smaller_text, italic } ) } | js-t-title`} aria-hidden="true">
                <RichText richText={text} quoted/>
              </blockquote>

              <figcaption ref={avatarRef} style={{ opacity: 0 }}>
                {author_image && (
                  <img className={`${s.quote__avatar} | js-t-title`} src={author_image?.file.url} alt={author_image?.description} />
                )}
                {author_name && (<div className={s.quote__authorName}>- {author_name}</div>)}
                {author_position && (<div className={s.quote__authorPosition}>{author_position}</div>)}
              </figcaption>

            </figure>

          </div>
        </div>
      </Container>
    </div>
  )
};
