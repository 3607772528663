import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { Seo } from 'components/contentful/seo/Seo';
import { Hero } from 'components/contentful/hero/Hero';
import { MediaTextContentful } from 'components/contentful/media-text/MediaText';
import { MediaTextFeatures } from 'components/contentful/media-text-features/MediaTextFeatures';
import { InfoListContentful } from 'components/contentful/info-list/InfoList';
import { QuoteContentful } from 'components/contentful/quote/Quote';
import { BgGradient } from 'components/bg-svgs/gradients/BgGradient';
import { WrapperBgs } from 'components/container/WrapperBgs'
import { BgLine1 } from 'components/bg-svgs/lines/homepage-connect/bg-line-1/BgLine1';
import { BgLine7 } from 'components/bg-svgs/lines/home/bg-line-7/BgLine7';
import { BgLine11 } from 'components/bg-svgs/lines/home/bg-line-11/BgLine11';
import { BgLine2 } from 'components/bg-svgs/lines/homepage-connect/bg-line-2/BgLine2';
import { Schema } from 'components/schema/Schema';
import { Mesh } from 'components/bg-svgs/mesh/mesh/Mesh';
import { UIContext } from 'context/ui';
import { BillboardTextContentful } from 'components/contentful/billboard-text/BillboardText';

import gsap from 'gsap';
import DrawSVGPlugin from '../utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitText from '../utils/SplitText/SplitText';
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ImageCTAs } from 'components/image-ctas/image-ctas';
import { VideosGallery } from 'components/contentful/videos-gallery/VideosGallery';
import {LogosGrid} from 'components/logos-grid/logos-grid';
import { BgLine3 } from 'components/bg-svgs/lines/homepage-connect/bg-line-3/BgLine3';
import { BgLine4 } from 'components/bg-svgs/lines/homepage-connect/bg-line-4/BgLine4';
import { BgLine5 } from 'components/bg-svgs/lines/homepage-connect/bg-line-5/BgLine5';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(CSSRulePlugin);


export default ({ data }: any) => {

  const { isMobile, isDesktop } = useContext(UIContext);
  const {contentfulData} = data;
  const seo = contentfulData.seo;
  return (
    <>
    {
      seo &&
      <Seo seo={seo}/>
    }
      <BgGradient />

      <WrapperBgs overflowMobile={true}>
        <Hero clearPaddingBottom={true}
          hero={{...contentfulData.hero, clearPaddingBottom: true}}
        />
        <ImageCTAs ctas={contentfulData.body[0]}/>
        {isDesktop && <BgLine1/>}
      </WrapperBgs>

      <WrapperBgs>
        {isDesktop && <BgLine2/>}
        <BillboardTextContentful billboardText={contentfulData.body[1]}/>
      </WrapperBgs>

      <WrapperBgs>
        <InfoListContentful
        titlePlacement='inside'
        titleAlignTop={true}
        infoList={contentfulData.body[2]}/>
        {isDesktop && <BgLine3/>}
      </WrapperBgs>

      <WrapperBgs>
        <MediaTextFeatures
          mediaTextFeatures={contentfulData.body[3]}
        />
        {isDesktop && <BgLine4/>}
      </WrapperBgs>

      <WrapperBgs>
        <VideosGallery videosGallery={contentfulData.body[4]}
            marginLeft={isMobile  ? 0 : '12.5%'}
          />
        <LogosGrid list={contentfulData.body[5]}/>
        <QuoteContentful smaller_text={true} quote={contentfulData.body[6]}/>
        {isDesktop && <BgLine5/>}
      </WrapperBgs>
      
      <WrapperBgs>
        <MediaTextContentful layout='column' mediaText={contentfulData.body[7]}/>
      </WrapperBgs>
    </>
  );
};

export const query = graphql`
{
  contentfulData: contentfulPage(name: {eq: "Home Page (new) - Page"}) {
        name
        seo {
          description
          title
          twitterImage {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            description
          }
          facebookImage {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            description
          }
          no_index
          no_follow
          name
        }
        body {
          ... on ContentfulBillboard {
            id
            title
            text {
              raw
            }
          }
          ... on ContentfulInfoList {
            id
            title
            description {
              raw
            }
            items {
              image {
                description
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
              title
              text {
                raw
              }
              video {
                file {
                  url
                }
                description
              }
            }
            subtitle
          }
          ... on ContentfulMediaTextFeatures {
            id
            title
            text {
              raw
            }
            items {
              description {
                raw
              }
              icon {
                description
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
              title
            }
            image {
              description
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          ... on ContentfulList {
            id
            title
            text {
              raw
            }
            cta {
              label
              href
            }
            items {
              title
              text {
                raw
              }
              image {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
                
                description
              }
              cta {
                href
                label
              }
              video {
                description
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
            }
            image {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              description
            }
          }
          ... on ContentfulQuote {
            id
            author
            text {
              raw
            }
          }
          ... on ContentfulVideosGallery {
            id
            title
            subtitle
            items {
              title
              videoUrl
              video {
                description
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                  url
                }
              }
              image {
                file {
                  details {
                    image {
                      width
                      height
                    }
                  }
                  url
                }
              }
              text {
                raw
              }
            }
          }
          ... on ContentfulMediaText {
            id
            videoUrl
            cta {
              label
              href
            }
            title
            text {
              raw
            }
            sectionBackground {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
                url
              }
              description
            }
          }
        }
        hero {
          title
          text {
            raw
          }
        }
      }
}
`