import { Image } from "components/contentful/image/Image";
import { Container } from "components/container/Container";
import React from "react";
import s from './logos-grid.scss';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import richTextOptions from "utils/richTextOptions";

export const LogosGrid = ({list}:any) => {
    const items = list.items.map((item:any, i:number) => {
        return (
            <div className={s('logoContainer')} key={i}>
                <Image className={s('logo')} image={item.image}/>
            </div>
        )
    })

    return (
        <Container>
            <div className={s('container')}>
                <div className={s('titleSection')}>
                {
                    list.title &&
                    <div className={s('title')}>
                        {list.title}
                    </div>
                }
                {
                    list.text &&
                    <div className={s('text')}>
                        {renderRichText(list.text, richTextOptions)}
                    </div>
                }
                </div>
                
                <div className={s('grid')}>
                    {items}
                </div>
            </div>
        </Container>
    )
}