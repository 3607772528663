import React, { useEffect, useRef, useState } from "react";

import "plyr/dist/plyr.css";
import s from "./VideoPlayer.scss";
import playIcon from "assets/images/play-triangle.png";

export const VideoPlayer = ({ videoItem, autoPlay = false }: any) => {
  const vid = useRef<any>(null);
  const item = videoItem;
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [clicked, setClicked] = useState(false);
  const runAutoPlay = autoPlay;
  const onButtonClick = () => {
    setClicked(true);
    videoPlayer.toggleControls(true);
    videoPlayer.play();
  };

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (vid.current) {
        const Plyr = require("plyr");
        const player = new Plyr(vid.current, {
          controls: [],
          volume: 0,
          muted: runAutoPlay,
          hideControls: runAutoPlay,
          vimeo: {
            background: false,
            muted: runAutoPlay,
            autoPlay: runAutoPlay
          }
        });
        if (runAutoPlay) {
          vid.current?.setAttribute("muted", "true");
          vid.current?.setAttribute("autoPlay", "true");
        }
        player.on("ready", () => {
          player.toggleControls(false);
        });
        setVideoPlayer(player);
      }
    }
    return () => {
      setVideoPlayer(null);
    };
  }, []);

  useEffect(() => {
    if (videoPlayer && item.videoUrl) {
      if (item.videoUrl.includes("vimeo")) {
        videoPlayer.source = {
          type: "video",
          sources: [
            {
              src: item.videoUrl,
              provider: "vimeo",
              size: 720
            }
          ]
        };
        videoPlayer.on("play", () => {
          videoPlayer.muted = false;
        });
      }
    }
  }, [videoPlayer]);

  return (
    <div className={s("item")}>
      {!clicked && !runAutoPlay && (
        <div className={s.overlay}>
          <button onClick={onButtonClick} className={s.playButton}>
            <img src={playIcon} /> PLAY NOW
          </button>
        </div>
      )}
      {
        <video
          ref={vid}
          className={s("player")}
          id="player"
          muted
          autoplay
          loop
          playsinline
          style={{
            "--plyr-control-icon-size": "20px",
            "--plyr-video-control-color": "#1368ea",
            "--plyr-video-control-color-hover": "transparent",
            "--plyr-video-controls-background": "transparent",
            "--plyr-color-main": "#fff",
            "--plyr-control-spacing": "20px",
            "--plyr-video-background": "transparent"
          }}
        >
          {item.video && <source src={item.video.file.url} type="video/mp4" />}
        </video>
      }
    </div>
  );
};
