import React from "react";
import s from "./BgLine1.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

export const BgLine1 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 2.5,
        delay: 1.5,
        drawSVG: "100%",
        ease: "linear"
      }
    );
  }, []);
  return (
    <div id="hero__line" className={s.hero__line}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        fill="none"
      >
        <path
          ref={lineRef}
          xmlns="http://www.w3.org/2000/svg"
          opacity="0.2"
          d="M316.723 0V0C316.723 82.8997 249.52 150.103 166.62 150.103H158.912C71.7549 150.103 1.10019 220.735 1.10019 307.891C1.10019 384.398 1.10019 475.059 1.10019 547C1.10019 646 -5.27686 722.5 83.7234 722.5C216.533 722.5 1136 723.167 1265.5 722.5"
          stroke="#1B52AD"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};
