import s from './image-ctas.scss'
import React from 'react';
import { Button } from 'components/button/Button';
import { Image } from 'components/contentful/image/Image';
import { Container } from 'components/container/Container';


export const ImageCTAs = ({ctas}: any) => {
    return (
        <Container>
            <div className={s('container')}>
            <div className={s('item', 'left')}>
                <div className={s('overlay')}>
                    <Button
                        colorOverride='#fff'
                        label={ctas.items[0].cta.label}
                        href={ctas.items[0].cta.href}
                    />
                </div>
                <div className={s('image')}>
                    <video className={s('image')} muted playsInline autoPlay loop width="505" height="505">
                        <source src={ctas.items[0].video.file.url} type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className={s('item', 'right')}>
                <div className={s('overlay')}>
                    <Button
                        colorOverride='#fff'
                        label={ctas.items[1].cta.label}
                        href={ctas.items[1].cta.href}
                    />
                </div>
                <div className={s('image')}>
                    <video className={s('image')} muted playsInline autoPlay loop width="505" height="505">
                        <source src={ctas.items[1].video.file.url} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
        </Container>
        
    )
}